@import '../../node_modules/nouislider/distribute/nouislider.css';

$handle-width: 44px;

.ariflex-slider {
  &.noUi-horizontal {
    height: 12px;
    .noUi-handle {
      height: 20px;
      .noUi-tooltip {
        opacity: 0.7;
        font-size: 12px;
      }
      &:hover {
        .noUi-tooltip {
          opacity: 1;
        }
      }
      &:before, &:after {
        height: 7px;
        //display: none;
      }
    }
  }

  &.ariflex-slider_inside {
    &.noUi-horizontal {
      .noUi-handle {
        width: $handle-width;
        left: -$handle-width / 2;
        .noUi-tooltip {
          font-size: 11px;
          padding: 3px;
          display: block;
          position: absolute;
          border: none;
          border-radius: 0;
          bottom: 0;
          opacity: 1;
          background: none;
        }
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  &__input {
    border: 1px solid #d8d8d8;
    padding: 2px 5px 0 15px;
    margin: 0;
    height: 30px;
    width: calc(50% - 15px);
    line-height: 30px;
    &-dash {
      margin: 0 8px;
      width: 14px;
      text-align: center;
      overflow: hidden;
    }
    &-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}